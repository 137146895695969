<template>
  <ListFrame
    :getPageFn="getPage"
    :immediateInit="false"
    :viewConf="{showLvl:true, showTitle:true, showReorder:false}"
    :customedParams="customedParams"
  >
    <template slot="conditions" slot-scope="{requestFn}">
      <FirstCategory
        classification="game"
        class="typeSelect"
        v-model="customedParams.contentType"
        @input="requestFn()"
      />
      <Modal v-model="showEditingForm" title="创建成语接龙" width="100" footer-hide>
        <IdiomCombForm v-if="showEditingForm" :formData="editData" @close="showEditingForm=false" />
      </Modal>
    </template>

    <template slot-scope="{item,requestFn}">
      <TaskItem
        :idomComb="item"
        style="margin-bottom:1em;flex:1"
        v-if="item.contentType === 'cnIdiomComb'"
      >
        <template v-slot:tags-editor>
          <Button type="primary" @click="edit(item)">编辑</Button>
        </template>
      </TaskItem>
      <FlyingShowItem
        :data="item"
        v-if="item.contentType === 'cnFlying'"
        type="1"
        @refresh="requestFn()"
      ></FlyingShowItem>
    </template>
  </ListFrame>
</template>

<script>
import TaskItem from './TaskItem';
import IdiomCombForm from './IdiomCombForm';
import FlyingShowItem from './FlyingShowItem';
import { getRecycleList, _configType } from './services';

export default {
  components: { FlyingShowItem, TaskItem, IdiomCombForm },
  data() {
    return {
      showEditingForm: false,
      editData: null,
      customedParams: { contentType: 'cn', type: 'cn' }
    };
  },
  methods: {
    getPage(params) {
      params.gameType = _configType[params.contentType];
      return getRecycleList(params);
    },
    edit(item) {
      this.editData = item;
      this.showEditingForm = true;
    }
  },
  beforeMount() {}
};
</script>

<style scoped>
.typeSelect {
  width: 200px;
  margin-right: 20px;
}
</style>
