import { render, staticRenderFns } from "./GameRecycle.vue?vue&type=template&id=2925f9d1&scoped=true&"
import script from "./GameRecycle.vue?vue&type=script&lang=js&"
export * from "./GameRecycle.vue?vue&type=script&lang=js&"
import style0 from "./GameRecycle.vue?vue&type=style&index=0&id=2925f9d1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2925f9d1",
  null
  
)

export default component.exports